import Link from "next/link";
import Style from "./Header.module.scss"
import Image from "next/image";

import User from "./User";
import Cart from "./Cart";
import Menu from "./Menu";
import Language from "./Language";
import Search from "./Search";
import TopHat from "./TopHat";
import clsx from "clsx";
import Localize from "./Localize";
import Logo from "./Logo";
import { Suspense } from "react";
import Loader from "@/components/feedback/Loader";
import { IconAssurance } from "@/icons/IconAssurance";


function Header({ theme = null, showSearch = true, showTopHat = true }){
    return(
        <header className={clsx(Style.block, Style[theme])}>
            {/* {showTopHat && (
                <TopHat {...{ theme }} />
            )} */}

            <div className={Style.top}>
                <div className={Style.logoMenu}>
                    <Link href="/" className={Style.logo}>
                        <Logo {...{ theme }} />
                    </Link>

                    <Menu {...{ theme }} />

                    <div className={clsx(Style.cartMobile)}>
                        <Cart {...{ theme }} />
                    </div>
                </div>


                {showSearch && (
                    <Search />
                )}
                

                <ul className={Style.navList}>
                    <li className={clsx(Style.navItem, Style[`nav__${theme}`])}>
                        <Link href="/l/purchase-assurance" className={Style.navItemLink}>
                            Purchase Assurance
                        </Link>
                    </li>

                    <li className={clsx(Style.navItem,  Style[`nav__${theme}`])}>
                        <Link href="/l/become-a-vendor" className={Style.navItemLink}>
                            Become a vendor
                        </Link>
                    </li>

                    {/* <li className={clsx(Style.navItem,  Style[`nav__${theme}`])}>
                        <Link href="/help">
                            Help Center
                        </Link>
                    </li> */}

                    <li className={clsx(Style.navItem,  Style[`nav__${theme}`])}>
                        <Cart {...{ theme }} />
                    </li>
                </ul>

                {/* <ul className={Style.aux}>
                    <li className={Style.auxItem}>
                        <Suspense fallback={<Loader isLoading />}>
                            <Localize {...{ theme }} />
                        </Suspense>
                    </li>

                    <li className={Style.auxItem}>
                        <Language {...{ theme }} />
                    </li>

                    <li className={Style.auxItem}>
                        <User {...{ theme }} />
                    </li>

                    <li className={Style.auxItem}>
                        <Cart {...{ theme }} />
                    </li>
                </ul> */}
            </div>


            {/* <nav className={Style.nav}>
                <ul className={Style.navList}>
                    <li className={Style.navItem}>
                        <Menu {...{ theme }} />
                    </li>

                    <li className={clsx(Style.navItem,  Style[`nav__${theme}`])}>
                        <Link href="/b/deals" className={Style.mainLink}>
                           Deals
                        </Link>
                    </li>

                    <li className={clsx(Style.navItem,  Style[`nav__${theme}`])}>
                        <Link href="/b/new" className={Style.mainLink}>
                            What's New!
                        </Link>
                    </li>
                </ul>
               

                <ul className={Style.navList}>
                    

                    <li className={clsx(Style.navItem, Style[`nav__${theme}`])}>
                        <Link href="/l/purchase-assurance" className={Style.navItem}>
                            <IconAssurance className={Style.icon} />
                            Purchase Assurance
                        </Link>
                    </li>

                    <li className={clsx(Style.navItem,  Style[`nav__${theme}`])}>
                        <Link href="/l/become-a-vendor">
                            Become a vendor
                        </Link>
                    </li>

                    <li className={clsx(Style.navItem,  Style[`nav__${theme}`])}>
                        <Link href="/help">
                            Help Center
                        </Link>
                    </li>

                    <li className={clsx(Style.navItem,  Style[`nav__${theme}`])}>
                        <Cart {...{ theme }} />
                    </li>
                </ul>
            </nav> */}

            
        </header>
    )
}


export default Header;